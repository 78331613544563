import React from "react";
import Styles from "./style.module.scss";
import ClassImage from "@Theme/ClassImage";

export default function ClassCard({onClick, overlayText,label, value,grade}) {
  return (
    <div className={Styles.classCard} onClick={onClick}>
      <div className={Styles.cardImage}>
        <div className={Styles.imageOverlay}>
          <p>{overlayText}</p>
        </div>
        <img src={ClassImage(grade)} alt="" />
      </div>
      <div className={Styles.cardBody}>
        <div className={Styles.cardText}>
          <p>{label}</p>
          <p>{value}</p>
        </div>
      </div>
    </div>
  );
}

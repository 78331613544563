import Button from "@Atom/Button";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import PhotoField from "@Atom/PhotoField";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import InputField from "@Molecule/InputField";
import Images from "@Theme/Images";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import FormErrorMessage from "@Atom/FormErrorMessage";
import useWindowSize from "@Hooks/useWindowSize";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
import Modal from "@Atom/Modal";
import { createStudent, getAcademicYearList } from "@Services/admin";

export default function AdminAddStudent() {
  const user = decryptStorageData("user", localStorage);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [success, setsuccess] = useState(false);
  const [academicYearOption, setAcademicYearOption] = useState([]);
  const { width } = useWindowSize();
  const [form, setForm] = useState({
    img: null,
    name: "",
    phoneNumber: "",
    guardians: [
      {
        type: "Ayah Kandung",
        name: "",
        occupation: "",
        role: "FATHER",
      },
      {
        type: "Ibu Kandung",
        name: "",
        occupation: "",
        role: "MOTHER",
      },
      {
        type: "Wali",
        name: "",
        occupation: "",
        role: "GUARDIAN",
      },
    ],
  });

  const handleSubmit = async () => {
    const payload = {
      ...form,
      gender: form.gender === "Laki-laki" ? "MALE" : "FEMALE",
    };
    delete payload.img;
    try {
      await createStudent(payload);
      setsuccess(true);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const getAcademicYearOption = async () => {
    try {
      const res = await getAcademicYearList();
      setAcademicYearOption(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAcademicYearOption();
  }, []);

  const handleChangeForm = (name, newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const handleGuardianChange = (index, key, value) => {
    const temp = { ...form };
    temp.guardians[index][key] = value;
    setForm(temp);
  };

  const template = useMemo(() => {
    return [
      {
        title: "Informasi Akun",
        component: (
          <div className={Styles.informationSection}>
            <PhotoField
              value={form?.img}
              setValue={(newVal) => handleChangeForm("img", newVal)}
              setAvatar={(newVal) => handleChangeForm("profilePicture", newVal)}
            />
            <div className={Styles.infoFields}>
              <div className={Styles.grid2}>
                <InputField
                  title={"Nama"}
                  value={form?.name}
                  setValue={(newVal) => handleChangeForm("name", newVal)}
                  placeholder="Masukkan nama siswa"
                  required
                />
                <InputField
                  title={"Jenis Kelamin"}
                  value={form?.gender}
                  setValue={(newVal) => handleChangeForm("gender", newVal)}
                  placeholder="Belum Dipilih"
                  required
                  isDropdown
                  dropdownOptions={["Laki-laki", "Perempuan"]}
                />
              </div>
              <div className={Styles.grid}>
                <InputField
                  title={"No. Handphone"}
                  value={form?.phoneNumber}
                  setValue={(newVal) => handleChangeForm("phoneNumber", newVal)}
                  placeholder="Masukkan No. handphone"
                  required
                  isPhoneNumber
                />
                <InputField
                  title={"Email"}
                  value={form?.email}
                  setValue={(newVal) => handleChangeForm("email", newVal)}
                  placeholder="Masukkan email"
                  required
                />
              </div>
              <div className={Styles.grid}>
                <InputField
                  title={"NISN"}
                  value={form?.idNumber}
                  setValue={(newVal) => handleChangeForm("idNumber", newVal)}
                  placeholder="Masukkan NISN siswa"
                  required
                />
                <InputField
                  title={"Password"}
                  value={form?.password}
                  setValue={(newVal) => handleChangeForm("password", newVal)}
                  placeholder="Masukkan Password"
                  required
                  isPassword
                />
              </div>
              <div className={Styles.grid}>
                <InputField
                  title={"Kelas"}
                  value={form?.grade}
                  setValue={(newVal) => handleChangeForm("grade", newVal)}
                  placeholder="Masukkan NISN siswa"
                  required
                  isDropdown
                  dropdownOptions={[
                    "SD Kelas 1",
                    "SD Kelas 2",
                    "SD Kelas 3",
                    "SD Kelas 4",
                    "SD Kelas 5",
                    "SD Kelas 6",
                    "SMP Kelas 7",
                    "SMP Kelas 8",
                    "SMP Kelas 9",
                    "SMA Kelas 10",
                    "SMA Kelas 11",
                    "SMA Kelas 12",
                  ]}
                />
                <InputField
                  title={"Tahun Ajaran"}
                  value={form?.academicYear}
                  setValue={(newVal) =>
                    handleChangeForm("academicYear", newVal)
                  }
                  placeholder="Pilih tahun ajaran"
                  required
                  isDropdown
                  dropdownOptions={academicYearOption}
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Informasi Lainnya",
        component: (
          <div className={Styles.bankAccountSection}>
            <div className={Styles.grid}>
              <InputField
                title={"Tempat Lahir"}
                value={form?.placeOfBirth}
                setValue={(newVal) => handleChangeForm("placeOfBirth", newVal)}
                placeholder="Masukan tempat lahir"
                required
              />
              <InputField
                title={"Tanggal Lahir"}
                value={form?.dateOfBirth}
                setValue={(newVal) => handleChangeForm("dateOfBirth", newVal)}
                placeholder="Pilih tanggal"
                isDate
                required
              />
            </div>
            <div className={Styles.grid}>
              <InputField
                title={"Agama"}
                value={form?.religion}
                setValue={(newVal) => handleChangeForm("religion", newVal)}
                placeholder="Belum dipilih"
                isDropdown
                dropdownOptions={[
                  "Islam",
                  "Kristen",
                  "Hindu",
                  "Budha",
                  "Katolik",
                  "Konghucu",
                ]}
                required
              />
              <InputField
                title={"Anak Ke"}
                value={form?.childOrder}
                setValue={(newVal) => handleChangeForm("childOrder", newVal)}
                placeholder="Contoh : 2"
                isNumber
                required
              />
            </div>
            <InputField
              title={"Alamat Siswa"}
              value={form?.address}
              setValue={(newVal) => handleChangeForm("address", newVal)}
              placeholder="Tuliskan alamat siswa"
              isTextArea
              required
            />
            {form?.guardians?.map((guardian, index) => (
              <div className={Styles.grid} key={index}>
                <InputField
                  title={"Nama " + guardian.type}
                  value={guardian.name}
                  setValue={(newVal) =>
                    handleGuardianChange(index, "name", newVal)
                  }
                  placeholder={"Masukkan Nama " + guardian.type}
                />
                <InputField
                  title={"Pekerjaan " + guardian.type}
                  value={guardian.occupation}
                  setValue={(newVal) =>
                    handleGuardianChange(index, "occupation", newVal)
                  }
                  placeholder={"Masukkan pekerjaan " + guardian.type}
                />
              </div>
            ))}
            <FormErrorMessage message={error} />
            <div className={Styles.submit}>
              <Button
                text={"Simpan"}
                disabled={
                  !form?.name ||
                  !form?.phoneNumber ||
                  !form?.gender ||
                  !form?.childOrder ||
                  !form?.academicYear || 
                  !form?.profilePicture ||
                  !form?.idNumber || 
                  !form?.placeOfBirth ||
                  !form?.dateOfBirth ||
                  !form?.religion ||
                  !form?.address ||
                  !form?.password ||
                  !form?.email ||
                  !form?.grade
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, form]);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div
          className={Styles.backIcon}
          onClick={() => navigate("/admin/student")}
        >
          <Icon size={16} icon={"chevron-left"} />
        </div>
        <div className={Styles.breadcrumbs}>
          <p>Daftar Instruktur</p>
          <Icon size={16} icon={"chevron-right"} />
          <p>{"Tambah Siswa"}</p>
        </div>
      </div>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
            <span>
              Silahkan lengkapi data dibawah ini untuk menambah siswa baru
            </span>
          </div>
          {width > 768 && (
            <img className={Styles.img} src={Images.ID_CARD} alt="" />
          )}
        </DoodleGradientCard>
        <div className={Styles.content}>
          {template?.map((t, tI) => (
            <div key={tI} className={Styles.each}>
              <span>{t?.title}</span>
              {t?.component}
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={success}>
        <div className={Styles.successModal}>
          <div className={Styles.modalBody}>
            <p>{"Siswa Berhasil Ditambahkan"}</p>
            <img src={Images.SUCCESS_MODAL} alt="" />
            <p>{`Siswa ${form.name} ditambahkan.`}</p>
          </div>
          <div className={Styles.modalAction}>
            <Button text="Tutup" onClick={() => navigate("/admin/student")} />
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Footer from "@Molecule/Footer";
import Button from "@Atom/Button";
import Searchbar from "@Atom/Searchbar";
import Table from "@Molecule/Table";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import Icon from "@Atom/Icon";
import InputSelect from "@Atom/InputSelect";

export default function StudentByYearList({ dataStudent, isLoading }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const tabQuery = query?.get("tab");
  const searchQuery = query?.get("search");
  const progressQuery = query?.get("progress");
  const reportQuery = query?.get("report");
  const gradeQuery = query?.get("grades");
  const academicYearQuery = query?.get("academicYear");
  const [tab, setTab] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);

  const [progress, setProgress] = useState("");
  const [report, setReport] = useState("");
  const [triggerFilter, setTriggerFilter] = useState("");

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setTab(+tabQuery || 1);
    setSearchTerm(searchQuery || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitQuery, pageQuery, searchQuery, tabQuery, triggerFilter]);
  // useEffect(() => {
  //   setData(dataStudent);
  // }, [dataStudent]);

  useEffect(() => {
    setProgress(progressQuery || "");
    setReport(reportQuery || "");
    setTriggerFilter(true);
  }, []);

  const debouncedSearchQuery = useDebounce(searchTerm, 500);
  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    navigate(
      `${pathname}?s=1&limit=${limit}&page=${page}&search=${debouncedSearchQuery}&academicYear=${academicYearQuery}&grades=${gradeQuery}&tab=${tab}${
        progress ? "&progress=" + progress : ""
      }${report ? "&report=" + report : ""}`,
      {
        replace: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, limit, page, tab, triggerFilter]);

  const template = useMemo(() => {
    return {
      data: dataStudent?.data?.map((obj) => {
        return {
          ...obj,
          id: obj?._id,
        };
      }),
      // .filter((el) =>
      //   el.name.toLowerCase().includes(searchTerm.toLowerCase())
      // )
      // .slice(limit * (page - 1), limit * page),
      columns: [
        {
          name: "",
          title: "Nama Siswa",
          renderData: (row) => (
            <div
              className={Styles.student}
              onClick={() => navigate(`detail/${row?._id}`)}
            >
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "idNumber",
          title: "NISN",
        },
        {
          name: "phoneNumber",
          title: "No Telpon",
        },
        {
          name: "",
          title: "Progress",
          renderData: (row) => <span>{Math.round(row?.grade.progress)}%</span>,
          align: "right",
        },
        {
          name: "",
          title: "Penerbitan Rapor",
          align: "center",
          renderData: (row) => (
            <div
              className={Styles.progressStatus}
              status={row.isPublished ? "published" : "false"}
            >
              <span>
                {row.isPublished ? "Diterbitkan" : "Belum Diterbitkan"}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataStudent, navigate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.filterWrapper}>
          <Searchbar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder="Cari Peserta"
          />
          <div
            className={Styles.filter}
            active={String(openFilter)}
            onClick={() => setOpenFilter(!openFilter)}
          >
            <Icon icon={"setting-4"} size={20} color={"#9E9E9E"} />
            <p>Filter</p>
          </div>
          <div
            className={Styles.dropdownContainer}
            is-open={String(openFilter)}
          >
            <div className={Styles.header}>
              <p>Filter</p>
            </div>
            <div className={Styles.divider} />
            <div className={Styles.body}>
              <div className={Styles.input}>
                <label htmlFor="batch">Progress Kelas</label>
                <InputSelect
                  value={progress}
                  setValue={setProgress}
                  placeholder={"Pilih Progress Siswa"}
                  position="bottom"
                  options={[
                    "Terendah",
                    "Tertinggi",
                    "Sudah 100%",
                    "Kurang Dari 100%",
                  ]}
                />
              </div>
              <div className={Styles.input}>
                <label htmlFor="batch">Rapor</label>
                <InputSelect
                  value={report}
                  setValue={setReport}
                  placeholder={"Pilih Progress Siswa"}
                  position="bottom"
                  options={["Diterbitkan", "Belum Diterbitkan"]}
                />
              </div>
            </div>
            <div className={Styles.divider} />

            <div className={Styles.filterAction}>
              <Button
                text={"Reset"}
                variant="outlined"
                disabled={progress.length === 0 && report.length === 0}
                onClick={() => {
                  setProgress("");
                  setReport("");
                }}
              />
              <Button
                text={"Terapkan"}
                onClick={() => {
                  setTriggerFilter(!triggerFilter);
                  setOpenFilter(false);
                }}
              />
            </div>
          </div>
        </div>
        <Table
          data={template?.data}
          totalData={dataStudent?.totalData}
          totalPage={Math.ceil(dataStudent.totalData / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading || isLoading || page !== +pageQuery}
          withNumbering
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Email</span>
                <span>{row?.email}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>No. Telepon</span>
                <span>{row?.phoneNumber}</span>
              </div>
            </div>
          )}
        />
      </div>
      <Footer />
    </div>
  );
}

import GradeByYearList from "@Organism/Admin/GradeByYearList";
import AcademicYearList from "@Organism/Admin/StudentByYear/AcademicYearList";
import { fetchGradeByYear, fetchStudentByYear } from "@Services/admin";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const academicYear = params?.get("years") || "";
  const getAllGrade = fetchStudentByYear(academicYear);
  return defer({
    getAllGrade,
  });
}

export default function StudentByYearPage() {
  const { getAllGrade } = useLoaderData();
  //   console.log(getAllStudent);

  return (
    <Suspense fallback={<AcademicYearList data={[]} isLoading />}>
      <Await
        resolve={getAllGrade}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AcademicYearList data={value.data} />}
      </Await>
    </Suspense>
  );
}
